<template>
  <section class="VSectionOrderHistory section-profile">
    <h2 class="section-profile__title">
      История операций
    </h2>
    <div class="VSectionOrderHistory__block">
      <div class="VSectionOrderHistory__switching">
        <button
          class="VSectionOrderHistory__switching__tabs"
          :class="{ tabs__active: orderType === 'listOrders' }"
          @click="switchType('listOrders')"
        >
          Список операций
        </button>
        <button
          class="VSectionOrderHistory__switching__tabs"
          :class="{ tabs__active: orderType === 'stuckTransactions' }"
          @click="switchType('stuckTransactions')"
        >
          Застрявшие транзакции <span
            :style="{color: (tipsCount + purchaseCount) > 0 && '#f00'}"
          >({{ tipsCount + purchaseCount }})</span>
        </button>
      </div>
      <div class="VSectionOrderHistory__line" />
      <template v-if="orderType === 'listOrders'">
        <div class="VSectionOrderHistory__filters">
          <VFiltersRestaurant
            :filter="ordersFilter"
            :waiters="waitersList"
            :shops="shopsList"
            :data-type="innerTabsData"
            :active-team="activeTeam"
            @updateFilter="updateFilter"
          />
          <VTimePeriod
            :filter="ordersFilter"
            @updateFilter="updateFilter"
          />
        </div>

        <div class="innerTabsHeader">
          <div
            class="innerTabsHeader__button"
            :class="{active : innerTabsData === 'all'}"
            @click="clickInnerTab('all', true)"
          >
            Основные
          </div>
          <div
            class="innerTabsHeader__button"
            :class="{active : innerTabsData === 'qr'}"
            @click="clickInnerTab('qr', true)"
          >
            По QR на чеках
          </div>
          <div
            class="innerTabsHeader__button"
            :class="{active : innerTabsData === 'personal'}"
            @click="clickInnerTab('personal', true)"
          >
            По индивидуальному QR
          </div>
        </div>


        <div class="VSectionOrderHistory__containerTableItem">
          <vLightPreloader
            v-if="lightPreloader"
            :fixed="false"
            :transparent="true"
          />
          <VOrderHistoryTableItem
            v-for="item in listOfItemsOrders"
            :key="item.date"
            :data-type="innerTabsData"
            :item="item"
            :active-table="activeTableItem"
            @activeItem="handlerActiveTableItem"
          />

          <div
            v-if="!listOfItemsOrders.length"
            class="VSectionOrderHistory__empty-label"
          >
            Нет результатов. Попробуйте ввести данные еще раз
          </div>

          <div
            v-if="ordersFilter.totalPage > ordersFilter.currentPage"
            class="VSectionOrderHistory__loadMore"
            @click="nextPage('orders')"
          >
            Загрузить еще
          </div>
        </div>
      </template>
      <template v-if="orderType === 'stuckTransactions'">
        <div class="VSectionOrderHistory__stuck">
          <button
            class="VSectionOrderHistory__stuck__button"
            :class="{ stuck__active: transactionType === 'basicTransaction' }"
            @click="switchTypeTransactions('basicTransaction')"
          >
            Основные
            <span
              style="color: #80869a"
            >({{ purchaseCount }})</span>
          </button>
          <button
            class="VSectionOrderHistory__stuck__button"
            :class="{
              stuck__active: transactionType === 'tippingTransactions',
            }"
            @click="switchTypeTransactions('tippingTransactions')"
          >
            Чаевые
            <span
              style="color: #80869a"
            >({{ tipsCount }})</span>
          </button>
          <div class="VSectionOrderHistory__stuck__info">
            <div class="VSectionOrderHistory__stuck__info__moneyButon">
              <div class="VSectionOrderHistory__stuck__info__money">
                <VIconTotalRubles />
                &nbsp;Итого застряло:
                <p class="VSectionOrderHistory__stuck__info__money__sum">
                  &nbsp;{{ totalAmountTransactions }}&nbsp;&#8381;
                </p>
              </div>
              <!--              <button class="pushAllButton">-->
              <!--                Протолкнуть все-->
              <!--              </button>-->
            </div>
            <div
              class="VSectionOrderHistory__stuck__info__numberStuckTransactions"
            >
              <span
                class="VSectionOrderHistory__stuck__info__numberStuckTransactions__text"
              >Застрявшие транзакции: {{ totalTransactions }} шт</span>
              <VIconError />
            </div>
          </div>
        </div>
        <div
          v-if="transactionType === 'basicTransaction'"
          class="VSectionOrderHistory__containerTableItem"
        >
          <VBasicStuckTable
            v-for="item in listOfItemsTransactions"
            :key="item.transaction_id"
            :item="item"
          />

          <div
            v-if="!listOfItemsTransactions.length"
            class="VSectionOrderHistory__empty-label"
          >
            Нет результатов. Попробуйте ввести данные еще раз
          </div>

          <div
            v-if="transactionsFilter.totalPage > transactionsFilter.currentPage"
            class="VSectionOrderHistory__loadMore"
            @click="nextPage('transaction')"
          >
            Загрузить еще
          </div>
        </div>
        <div
          v-if="transactionType === 'tippingTransactions'"
          class="VSectionOrderHistory__containerTableItem"
        >
          <VTippingTransactionsTable
            v-for="item in listOfItemsTransactions"
            :key="item.transaction_id"
            :item="item"
            :active-tips-transaction="activeTipsTransaction"
            @onActiveTipsTransaction="onActiveTipsTransaction"
          />

          <div
            v-if="!listOfItemsTransactions.length"
            class="VSectionOrderHistory__empty-label"
          >
            Нет результатов. Попробуйте ввести данные еще раз
          </div>

          <div
            v-if="transactionsFilter.totalPage > transactionsFilter.currentPage"
            class="VSectionOrderHistory__loadMore"
            @click="nextPage('transaction')"
          >
            Загрузить еще
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VTimePeriod from '@/components/orderHistory/v-time-period'
import VFiltersRestaurant from '@/components/orderHistory/v-filters-restaurant'
import VOrderHistoryTableItem from '@/components/orderHistory/v-order-history-table-item'
import VBasicStuckTable from '@/components/orderHistory/v-basic-stuck-table'
import VTippingTransactionsTable from '@/components/orderHistory/v-tipping-transactions-table'
import VIconTotalRubles from '@/assets/images/icons/v-icon-total-rubles'
import VIconError from '@/assets/images/icons/v-icon-error'
import axios from 'axios'
import vLightPreloader from '@/components/v-light-preloader'
import HistoryOperationsService from '@/services/historyOperations'

const INITIAL_FILTERS = {
  ordersFilter: {
    currentPage: 1,
    limit: 15,
    timePeriod: null,
    shopId: null,
    tableNumber: null,
    session_state: null,
    waiter: null,
    periodFrom: '',
    periodBefore: '',
    totalPage: 1,
    onlyReverse: 0,
  },
  listOrders: null,

  transactionsFilter: {
    currentPage: 1,
    limit: 15,
    totalPage: 1,
  },
  listTransactions: null,
}

export default {
  name: 'OrderHistory',
  components: {
    VTimePeriod,
    VFiltersRestaurant,
    VOrderHistoryTableItem,
    VIconTotalRubles,
    VIconError,
    VBasicStuckTable,
    VTippingTransactionsTable,
    vLightPreloader
  },
  data() {
    return {
      activeTipsTransaction: null,
      preloader: false,
      lightPreloader: false,
      orderType: 'listOrders',
      transactionType: 'basicTransaction',
      activeTableItem: null,

      purchaseCount: 0,
      tipsCount: 0,

      innerTabsData: 'all',

      ordersFilter: {
        currentPage: 1,
        limit: 15,
        timePeriod: 'DAY',
        shopId: null,
        tableNumber: null,
        session_state: null,
        waiter: null,
        periodFrom: '',
        periodBefore: '',
        totalPage: 1,
        onlyReverse: 0,
        only_shop_team: null
      },
      listOrders: null,

      transactionsFilter: {
        currentPage: 1,
        limit: 15,
        totalPage: 1,
      },
      listTransactions: null,

      waitersList: [],
      shopsList: [],
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    activeTeam() {
      return this.ordersFilter.only_shop_team === 1 && this.innerTabsData === 'personal' && this.ordersFilter.waiter === null
    },
    listOfItemsOrders() {
      return this.listOrders?.data || []
    },
    listOfItemsTransactions() {
      return this.listTransactions?.data || []
    },
    totalAmountTransactions() {
      if (this.listTransactions?.totalNotPaid) {
        const number = parseFloat(this.listTransactions?.totalNotPaid) / 100
        return number.toFixed(2)
      } else {
        return 0
      }
    },
    totalTransactions() {
      return this.listTransactions?.totalItem || 0
    },
  },
  watch: {
    ordersFilter: {
      deep: true,
      handler() {
        if (this.innerTabsData === 'all') {
          this.fetchOrders()
        }
        if (this.innerTabsData === 'qr') {
          this.clickInnerTab('qr')
        }
        if (this.innerTabsData === 'personal') {
          this.clickInnerTab('personal')
        }
      },
    },
    transactionsFilter: {
      deep: true,
      handler() {
        if (this.innerTabsData === 'all') {
          this.fetchTransactions()
        }
      },
    },
  },
  async mounted() {
    this.SET_TEXT_PRELOADER('Обновляем таблицу')
    this.TOGGLE_PRELOADER(true)

    await this.fetchOrders()
    await this.fetchShops()

    await this.getCountStuckTransactions()

    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER', 'SET_TEXT_PRELOADER']),
    async getCountStuckTransactions () {
      const params = { page: 1, limit: 1}
      const headers = { Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED }
      const purchaseUrl = 'admin-panel/not-paid-purchase/list'
      const tipsUrl = 'admin-panel/not-paid-tips/list'

      const { data: purchaseRes } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${purchaseUrl}`, {params,headers})
      this.purchaseCount = purchaseRes.result.totalItem

      const { data: tipsRes } = await axios.get(`${process.env.VUE_APP_BASE_URL}/${tipsUrl}`, {params,headers})
      this.tipsCount = tipsRes.result.totalItem
    },

    async clickInnerTab (type, reset = false) {
      this.lightPreloader = true
      this.innerTabsData = type

      const params = {
        page: this.ordersFilter.currentPage,
        limit: this.ordersFilter.limit,
        token: this.GET_AUTHENTIFICATED
      }

      if (reset) {
        this.ordersFilter = {
          currentPage: 1,
          limit: 15,
          timePeriod: 'DAY',
          shopId: null,
          tableNumber: null,
          session_state: null,
          waiter: null,
          periodFrom: '',
          periodBefore: '',
          totalPage: 1,
          onlyReverse: 0,
          only_shop_team: null
        }

        this.listOrders = null
      }

      // this.ordersFilter.periodFrom = ''
      // this.ordersFilter.periodBefore = ''

      if (this.ordersFilter.timePeriod) {
        params.time_period = this.ordersFilter.timePeriod
      }

      if (this.ordersFilter.shopId) {
        params.shop_id = this.ordersFilter.shopId
      }

      if (this.ordersFilter.tableNumber) {
        params.table_code = this.ordersFilter.tableNumber
      }

      if (this.ordersFilter.status) {
        params.session_state = this.ordersFilter.status
      }

      if (this.ordersFilter.waiter) {
        params.waiter_id = this.ordersFilter.waiter
      }

      if (this.ordersFilter.periodFrom) {
        params.time_period_from = this.ordersFilter.periodFrom
      }

      if (this.ordersFilter.periodBefore) {
        params.time_period_before = this.ordersFilter.periodBefore
      }

      if (this.ordersFilter.timePeriod) {
        params.time_period = this.ordersFilter.timePeriod
      }

      if (this.ordersFilter.onlyReverse) {
        params.only_reverse = this.ordersFilter.onlyReverse
      }

      if (this.ordersFilter.only_shop_team) {
        params.only_shop_team = this.ordersFilter.only_shop_team
      }

      if (type === 'all') {
        await this.fetchOrders()
      }

      if (type === 'qr') {
        params.tips_type = 'ADVANCE_CHECK'

        const data = await HistoryOperationsService.fetchQr(params)
        if (this.ordersFilter.currentPage > 1) {
          this.listOrders.data = [
            ...this.listOrders.data,
            ...data.result.data,
          ]
        } else {
          this.listOrders = data.result
          this.ordersFilter.totalPage = data.result.totalPage
        }
      }

      if (type === 'personal' && params.only_shop_team !== 1) {
        params.tips_type = 'PERSONAL_QR'

        const data = await HistoryOperationsService.fetchQr(params)
        if (this.ordersFilter.currentPage > 1) {
          this.listOrders.data = [
            ...this.listOrders.data,
            ...data.result.data,
          ]
        } else {
          this.listOrders = data.result
          this.ordersFilter.totalPage = data.result.totalPage
        }
      }

      if (params.only_shop_team) {
        params.tips_type = 'PERSONAL_QR'

        const data = await HistoryOperationsService.fetchQr(params)
        if (this.ordersFilter.currentPage > 1) {
          this.listOrders.data = [
            ...this.listOrders.data,
            ...data.result.data,
          ]
        } else {
          this.listOrders = data.result
          this.ordersFilter.totalPage = data.result.totalPage
        }
      }

      this.lightPreloader = false
    },
    async fetchOrders() {
      if (this.loading) return

      const params = {
        page: this.ordersFilter.currentPage,
        limit: this.ordersFilter.limit,
      }

      if (this.ordersFilter.timePeriod) {
        params.time_period = this.ordersFilter.timePeriod
      }

      if (this.ordersFilter.shopId) {
        params.shop_id = this.ordersFilter.shopId
      }

      if (this.ordersFilter.tableNumber) {
        params.table_code = this.ordersFilter.tableNumber
      }

      if (this.ordersFilter.status) {
        params.session_state = this.ordersFilter.status
      }

      if (this.ordersFilter.waiter) {
        params.waiter_id = this.ordersFilter.waiter
      }

      if (this.ordersFilter.periodFrom) {
        params.time_period_from = this.ordersFilter.periodFrom
      }

      if (this.ordersFilter.periodBefore) {
        params.time_period_before = this.ordersFilter.periodBefore
      }

      if (this.ordersFilter.timePeriod) {
        params.time_period = this.ordersFilter.timePeriod
      }

      if (this.ordersFilter.onlyReverse) {
        params.only_reverse = this.ordersFilter.onlyReverse
      }

      try {
        this.loading = true

        const result = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/admin-panel/history-operations-for-support/list`,
          {
            params,
            headers: {
              Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED,
            },
          }
        )


        if (this.ordersFilter.currentPage > 1) {
          let oldData = this.listOrders.data
          let newData = result.data.result.data
          if (oldData[oldData.length - 1].date === newData[0].date) {
            let copyArr = JSON.parse(JSON.stringify(oldData))
            const lastEl = copyArr.pop()
            lastEl.sessions = [...lastEl.sessions, ...result.data.result.data[0].sessions]
            oldData = [...copyArr, lastEl]
            newData = newData.slice(1)
          }
          this.listOrders.data = [
            ...oldData,
            ...newData,
          ]
        } else {

          this.listOrders = result.data.result
          this.ordersFilter.totalPage = result.data.result.totalPage
        }
      } catch (e) {
        console.error('Ошибка загрузки данных', e)
      } finally {
        this.loading = false
      }
    },
    async fetchTransactions() {
      if (this.loading) return
      this.TOGGLE_PRELOADER(true)

      const params = {
        page: this.transactionsFilter.currentPage,
        limit: this.transactionsFilter.limit,
      }

      let url =
        this.transactionType === 'basicTransaction'
          ? '/admin-panel/not-paid-purchase/list'
          : '/admin-panel/not-paid-tips/list'

      try {
        this.loading = true

        const result = await axios.get(
          `${process.env.VUE_APP_BASE_URL}${url}`,
          {
            params,
            headers: {
              Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED,
            },
          }
        )

        if (this.transactionsFilter.currentPage > 1) {
          let oldData = this.listTransactions.data
          let newData = result.data.result.data
          if (oldData[oldData.length - 1].date === newData[0].date) {
            let copyArr = JSON.parse(JSON.stringify(oldData))
            const lastEl = copyArr.pop()
            // lastEl.sessions = [...lastEl.sessions, ...result.data.result.data[0].sessions]
            oldData = [...copyArr, lastEl]
            newData = newData.slice(1)
          }
          this.listTransactions.data = [
            ...oldData,
            ...newData,
          ]
        } else {
          this.listTransactions = result.data.result
          this.transactionsFilter.totalPage = result.data.result.totalPage
        }
      } catch (e) {
        console.error('Ошибка загрузки данных', e)
      } finally {
        this.loading = false

        setTimeout(() => {
          this.TOGGLE_PRELOADER(false)
        }, 500)
      }
    },
    async fetchWaiters() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/waiter/list-for-support`,
          {
            params: {
              page: 1,
              limit: 300,
              shop_id: this.ordersFilter.shopId,
            },
            headers: {
              Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED,
            },
          }
        )

        this.waitersList = response.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async fetchShops() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/list`,
          {
            params: {
              page: 1,
              limit: 1000,
            },
            headers: {
              Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED,
            },
          }
        )

        this.shopsList = response.data.result.data
      } catch (e) {
        console.log(e)
      }
    },
    switchType(type) {
      if (this.orderType === type || this.loading) return
      this.resetFilters()
      this.orderType = type

      if (type === 'listOrders') {
        this.ordersFilter = {
          currentPage: 1,
          limit: 15,
          timePeriod: 'DAY',
          shopId: null,
          tableNumber: null,
          session_state: null,
          waiter: null,
          periodFrom: '',
          periodBefore: '',
          totalPage: 1,
          onlyReverse: 0,
          only_shop_team: null
        }
        this.fetchOrders()
      } else {
        this.fetchTransactions()
      }
    },
    switchTypeTransactions(type) {
      if (this.transactionType === type || this.loading) return

      this.transactionType = type
      Object.assign(this.transactionsFilter, INITIAL_FILTERS.transactionsFilter)
      this.fetchTransactions()
    },
    async updateFilter(payload) {
      if (!payload.value) {
        this.ordersFilter.only_shop_team = null
      }

      if(payload.name !== 'team') {
        this.ordersFilter[payload.name] = payload.value
        this.ordersFilter.currentPage = 1
      }


      if (payload.name === 'team') {
        this.ordersFilter.waiter = null
        this.ordersFilter.only_shop_team = 1
      }

      if (payload.name === 'shopId') {
        this.ordersFilter.tableNumber = ''
        this.ordersFilter.waiter = ''

        await this.fetchWaiters()
      }
    },
    resetFilters() {
      Object.assign(this.ordersFilter, INITIAL_FILTERS.ordersFilter)
      Object.assign(this.transactionsFilter, INITIAL_FILTERS.transactionsFilter)
      this.listOrders = []
      this.listTransactions = []
      this.activeTableItem = null
    },
    handlerActiveTableItem(payload) {
      this.activeTableItem = payload
    },
    nextPage(type) {
      if (type === 'orders') {
        this.ordersFilter.currentPage += 1
      } else {
        this.transactionsFilter.currentPage += 1
      }
    },
    onActiveTipsTransaction(payload) {
      this.activeTipsTransaction = payload
    }
  },
}
</script>
<style lang="scss">
.VSectionOrderHistory {
  &__block {
    padding: 24px;
    background: #fff;
    border: 1px solid #e6e7eb;
    border-radius: 11px;
    width: 100%;
    //max-width: 1200px;
    @media (max-width: 769px) {
      padding: 20px 12px;
    }
  }
  &__switching {
    width: 100%;
    background: #f7f8fa;
    border-radius: 14px;
    height: 48px;
    padding: 4px;
    display: flex;
    align-items: center;
    &__tabs {
      color: #80869a;
      padding: 12px 17px;
      border-radius: 11px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      border: none;
      background: #f7f8fa;
      cursor: pointer;
    }
  }
  &__line {
    width: 100%;
    height: 4px;
    background: #f7f8fa;
    margin-top: 16px;
    margin-bottom: 25px;
  }
  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
  }
  &__containerTableItem {
    overflow: auto;
    height: 55vh;
    margin: 12px -16px 0 0;
    padding-right: 13px;
    position: relative;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #f7f8fa;
    }
    &::-webkit-scrollbar-thumb {
      background: #6764ff;
      border-radius: 2px;
    }
  }

  &__stuck {
    &__button {
      background: #fff;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #80869a;
      border: none;
      cursor: pointer;
      padding: 0;
      margin-right: 32px;
      span {
        color: #80869a;
      }
    }
    &__info {
      margin: 32px 0 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__moneyButon {
        display: flex;
        align-items: center;
      }
      &__money {
        padding: 0 8px 0 6px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        border: 1px solid #e6e7eb;
        border-radius: 6px;
        font-weight: 400;
        font-size: 15px;
        line-height: 120%;
        height: 30px;
        &__sum {
          font-weight: 500;
          font-size: 15px;
          line-height: 120%;
        }
      }
      &__numberStuckTransactions {
        display: flex;
        align-items: center;
        &__text {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-right: 7px;
        }
      }
    }
  }

  &__loadMore {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    font-weight: 600;
    font-size: 17px;
    background-color: #edeef1;
    border-radius: 12px;
    cursor: pointer;
  }

  &__empty-label {
    margin-top: 12px;
    font-size: 16px;
    color: #80869a;
  }

  .tabs__active {
    background: #fff;
    color: #010d35;
  }
  .stuck__active {
    color: #010d35;
    padding-bottom: 10px;
    border-bottom: 2px solid #6764ff;
  }
  .pushAllButton {
    cursor: pointer;
    width: 130px;
    height: 30px;
    color: #fff;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  .section-profile__title {
    @media (max-width: 769px) {
      display: none;
    }
  }
}
.innerTabsHeader {
  display: flex;
  &__button {
    color: #80869a;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;
    margin-right: 32px;
    &.active {
      color: #010D35;
      border-color: #6764FF;
    }
  }
}
</style>
